th:first-child {
    border-radius: 3px 0 0 3px;
    padding: 0px 0px 0px 10px;
}

.alternate-color-table th:last-child {
    border-radius: 0px 3px 3px 0px;
}

td:first-child {
    border-radius: 3px 0 0 3px;
    padding: 0px 0px 0px 10px;
}

td:last-child {
    border-radius: 0px 3px 3px 0px;
}


.alternate-color-table .alternate-color-table-body tr:nth-child(odd) {
    background-color: #56565663;
}

.alternate-color-table .alternate-color-table-body tr:nth-child(even) {
    background-color: #aeaeae63;
}